import React from "react";
import Info from "./Info";

import {
    FaFacebookSquare,
    FaFireAlt,
    FaInstagramSquare,
    FaLinkedin,
    FaTwitterSquare,
    FaXingSquare,
    FaYoutubeSquare
} from "react-icons/fa";

let iconSize = 28

const BusinessCard = () => {
    return (
        <div className="card">
            <div className="image">
                <img alt="Robin Willig Bild" src="robin2.jpg"/>
            </div>
            <div className="details">
                <div className="center">
                    <h1>Robin Willig<br/><span>Developer</span></h1>
                    <p>Content Creator, Podcaster, Barista, FPV-Pilot, Maker</p>
                    <ul className="Social">
                        <li>
                            <a aria-label="Facebook" href="https://www.facebook.com/derbushcraftrobin"
                               target="_blank" rel="noopener noreferrer">
                                <FaFacebookSquare size={iconSize}/>
                            </a>
                        </li>
                        <li>
                            <a aria-label="Twitter" href="https://twitter.com/realdragonito" target="_blank"
                               rel="noopener noreferrer">
                                <FaTwitterSquare size={iconSize}/>
                            </a>
                        </li>
                        <li>
                            <a aria-label="Xing" href="https://www.xing.com/profile/Robin_Willig"
                               target="_blank" rel="noopener noreferrer">
                                <FaXingSquare size={iconSize}/>
                            </a>
                        </li>
                        <li>
                            <a aria-label="Linkedin" href="https://www.linkedin.com/in/dragonito/"
                               target="_blank" rel="noopener noreferrer">
                                <FaLinkedin size={iconSize}/>
                            </a>
                        </li>
                        <li>
                            <a aria-label="Instagram" href="https://www.instagram.com/dragonito/"
                               target="_blank" rel="noopener noreferrer">
                                <FaInstagramSquare size={iconSize}/>
                            </a>
                        </li>
                        <li>
                            <a aria-label="Youtube"
                               href="https://www.youtube.com/@robinwillig" target="_blank"
                               rel="noopener noreferrer">
                                <FaYoutubeSquare size={iconSize}/>
                            </a>
                        </li>
                        <li>
                            <a aria-label="Bushcraft Podcast" href="https://bushcraft-podcast.de"
                               target="_blank" rel="noopener noreferrer">
                                <FaFireAlt size={iconSize}/>
                            </a>
                        </li>
                    </ul>
                    <Info/>

                </div>

            </div>

        </div>
    );
}

export default BusinessCard;