import React from "react";


const Info = () => {
    const version = process.env.npm_package_version;

    return (
        <div className="version">v1.0.1</div>
    );
}

export default Info;