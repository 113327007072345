import React, {} from "react";
import BusinessCard from "./Info/BusinessCard";


import "./App.css";

function App() {
    return (<div className="App">
        <header className="App-header">
            <BusinessCard/>
        </header>
    </div>);
}

export default App;
